import axios from "axios";
import { useState, useEffect } from "react";
import Hcaptcha from "../components/hcaptcha.jsx";

const Headline = () => {
  const [captchaToken, setCaptchaToken] = useState(false);
  const [wrongFormat, setWrongFormat] = useState();
  const [wrongURL, setWrongURL] = useState(false);
  const [serverError, setServerError] = useState();
  const [ratelimit, setRatelimit] = useState();
  const [notFound, setNotFound] = useState();
  const [errorOccured, setErrorOccured] = useState();
  const [loading, setLoading] = useState();
  const [slowWebsite, setSlowWebsite] = useState();
  const [uniqueID, setUniqueID] = useState();
  const [editID, setEditID] = useState();
  const [inputValue, setInputValue] = useState();

  const slowDomains = [
    "alo.rs",
    "24sedam.rs",
    "sd.rs",
    "b92.net",
    "informer.rs",
    "republika.rs",
    "rts.rs",
  ];

  const checkUrlFormatRegex =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/; //
  const irregularSlugRegex = /php\?yyyy/;

  //? gets random values from Astro edit uniqueID and editID
  const getRandomValues = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.PUBLIC_SITE_IP}get_random_strings/`,
        {
          headers: {
            "Content-Type": "application/json", // Use JSON content type
          },
        }
      );
      setUniqueID(response.data.uniqueID);
      setEditID(response.data.editID);
    } catch (error) {
      console.error("Error getting IDs:"); // Log the error for debugging
    }
  };

  //? runs getRandomValues once to get the values, and again if errorOccured changes
  useEffect(() => {
    getRandomValues();
  }, [errorOccured]);

  const getSlugFromURL = (urlToProcess) => {
    let slug;
    let navIdValue;
    let slugDashes;
    //? this regex is for b92 since it doesn't use regular slugs sometimes
    const url = urlToProcess;
    const slowWebsiteDetected = slowDomains.some((domain) =>
      url.includes(domain)
    );
    setSlowWebsite(slowWebsiteDetected);
    const urlFragments = url.split("/");
    urlFragments.forEach((fragment, index) => {
      const fragmentWithDashes = fragment.match(/-/g);
      if (fragmentWithDashes && fragmentWithDashes.length >= 1) {
        if (!slug) {
          slug = fragment;
        } else {
          slugDashes = slug.match(/-/g);
          if (fragmentWithDashes.length >= slugDashes.length) {
            slug = fragment;
          }
        }
        if (index === urlFragments.length - 1) {
          if (slug.includes(".sr.html")) {
            slug = slug.replace(/\.sr\.html$/, "");
          }
          if (slug.includes(".html")) {
            slug = slug.replace(/\.html$/, "");
          }
        }
      }
      //? only used for b92 if it doesn't use regular slug
      if (irregularSlugRegex.test(url)) {
        const navIdRegex = /nav_id=(\d+)/;
        const navIdMatch = navIdRegex.exec(url);
        if (navIdMatch) {
          navIdValue = navIdMatch[1];
        }
        slug = "b92-net-" + navIdValue;
      }
    });

    return slug;
  };
  const handleInputClick = () => {
    setInputValue(""); // Clear the input value when clicked
    setWrongFormat(false);
    setWrongURL(false);
    setServerError(false);
    setLoading(false);
    setRatelimit(false);
    setNotFound(false);
    setSlowWebsite(false);
    // if any error occured, check if errorOccured is true
    // if it is, on click set it to false and show Captcha again by setting CaptchaToken to false
    if (errorOccured) {
      setErrorOccured(false);
      setCaptchaToken(false);
    }
  };
  const saveArticle = async (event) => {
    if (captchaToken) {
      let inputFieldValue = event.target.value;
      setInputValue(inputFieldValue);

      if (
        inputFieldValue.includes("?") &&
        !irregularSlugRegex.test(inputFieldValue)
      ) {
        //? Remove everything after the question mark
        inputFieldValue = inputFieldValue.split("?")[0];
      }

      if (checkUrlFormatRegex.test(inputFieldValue)) {
        setWrongFormat(false);
        setServerError(false);
        let articleSlug = getSlugFromURL(inputFieldValue);
        try {
          setLoading(true);
          const response = await axios.post(
            import.meta.env.PUBLIC_API_IP_PROXY + "scrap/",
            {
              url: inputFieldValue,
              slug: articleSlug,
              edit_id: editID,
              unique_id: uniqueID,
              token: captchaToken,
            }
          );
          if (response.status === 201) {
            let articleUrl =
              import.meta.env.PUBLIC_SITE_IP +
              uniqueID +
              "/" +
              articleSlug +
              "/";
            let articleEditUrl = articleUrl + editID + "/";
            sessionStorage.setItem(
              "articleLinks",
              JSON.stringify([articleUrl, articleEditUrl])
            );
            window.location.href = articleUrl;
          }
        } catch (error) {
          if (error.response) {
            setErrorOccured(true);
            if (error.response.status === 400) {
              setWrongURL(true);
              setLoading(false);
              setSlowWebsite(false);
            }
            if (error.response.status === 403) {
              setRatelimit(true);
              setLoading(false);
              setSlowWebsite(false);
            }
            if (error.response.status === 404) {
              setNotFound(true);
              setLoading(false);
              setSlowWebsite(false);
            }
            if (error.response.status === 500) {
              setServerError(true);
              setLoading(false);
              setSlowWebsite(false);
            }
          }
        }
      } else {
        if (inputFieldValue === "") {
          setWrongFormat(false);
          setWrongURL(false);
          setServerError(false);
          setLoading(false);
          setRatelimit(false);
          setNotFound(false);
        } else {
          setWrongFormat(true);
        }
      }
    }
  };
  // ?RENDERING STARTS HERE

  return (
    <section className="websiteTitle">
      <h1 className="websiteTitle__headline">
        Sačuvaj omiljene članke samo jednim klikom miša
      </h1>
      {!captchaToken && (
        <>
          <input
            disabled
            className="websiteTitle__linkInput--disabled"
            type="text"
            placeholder="Prebacite vaš link ovde..."
          />

          <Hcaptcha handleCaptchaToken={setCaptchaToken} />

          <section className="post__deleteBox--mainPageInfo">
            <section className="closeButtonHolder">
              <p>
                Pre nego što probate da sačuvate članak, morate da rešite
                zagonetku klikom na <b>Ja sam čovjek</b> polje.
              </p>
            </section>
          </section>
        </>
      )}

      {captchaToken ? (
        loading ? (
          <input
            disabled
            className="websiteTitle__linkInput--disabled"
            type="text"
            placeholder="Vaš link se obrađuje, molimo vas sačekajte"
          />
        ) : (
          <input
            value={inputValue}
            onClick={handleInputClick}
            onChange={saveArticle}
            className="websiteTitle__linkInput"
            type="text"
            placeholder="Prebacite vaš link ovde..."
          />
        )
      ) : null}
      {loading && (
        <div className="websiteTitle__loading">
          <img
            className="websiteTitle__loading__image"
            src={import.meta.env.PUBLIC_SITE_IP + "spinner.gif"}
            alt="loading gif"
          />
        </div>
      )}
      {slowWebsite && (
        <section className="post__deleteBox--mainPageInfo">
          <section className="closeButtonHolder">
            <p>
              Ova stranica ima posebne karakteristike zbog kojih obrada može
              trajati <b>od 30 do 60 sekundi</b>. Molimo vas sačekajte.
            </p>
          </section>
        </section>
      )}

      {(wrongFormat || wrongURL || serverError || ratelimit || notFound) && (
        <section className="post__deleteBox--warning">
          <section className="closeButtonHolder">
            {wrongFormat && (
              <p>
                Link koji ste uneli nije u ispravnom formatu. Molimo vas da ga
                proverite.
              </p>
            )}
            {wrongURL && (
              <p>
                Link koji ste uneli nije podržan. Molimo vas probajte drugi
                izvor.
              </p>
            )}
            {notFound && (
              <p>
                Link koji ste uneli nije ispravan. Proverite tačnost linka i
                probajte ponovo.
              </p>
            )}
            {serverError && (
              <p>
                Problem sa serverom ili Internet konekcijom. Molimo vas probajte
                kasnije.
              </p>
            )}
            {ratelimit && (
              <p>
                Servis je pod velikim opterećenjem i trenutno ne može da obradi
                vaš zahtev. Molimo vas probajte kasnije.
              </p>
            )}
          </section>
        </section>
      )}
    </section>
  );
};

export default Headline;
